<template>
  <div id="header">
    <NavBar :message="status"></NavBar>
    <header>
      <p class="header_p1">阅图智能</p>
      <p class="header_p2">阅见世界 图观未来</p>
    </header>
    <main>
      <div id="main-nav" :class="{ is_fixed: isFixed }">
        <ul class="ul">
          <li
            v-for="(item, index) in navList"
            :key="index"
            @click="handleClick(index, item.id)"
          >
            <a href="javascript:void(10)">{{ item.name }}</a>
            <div :class="navActived == index ? 'blueLine' : ''"></div>
          </li>
        </ul>
      </div>
      <div id="profile" class="section">
        <p>公司简介</p>
        <p>
          阅图智能创立于2020年，通过AI技术为全球港口提供全面智慧化解决方案；不断提高港口生产效率和安全管理水平。
        </p>
      </div>
      <div id="qualification">
        <p class="p1">核心特点</p>
        <div class="qua-box">
          <div class="wrap1">
            <div class="wrap1_box1">
              <img src="../../assets/images/tedian1.png" alt="" />
            </div>
            <div class="wrap1_box2">
              <img src="../../assets/images/tedian2.png" alt="" />
            </div>
            <div class="wrap1_box3">
              <img src="../../assets/images/tedian3.png" alt="" />
            </div>
          </div>
          <div class="wrap2">
            <div class="content">
              <p>深耕行业</p>
              <p>
                丰富的行业实践经验，团队对行业生产、管理流程有着深刻的理解，针对用户痛点提出专业解决方案。
              </p>
            </div>
            <div class="content">
              <p>专业团队</p>
              <p>
                专业的管理及售后团队，前期能够深刻理解用户需求，后期针对售后问题全球快速响应。
              </p>
            </div>
            <div class="content">
              <p>定制化服务</p>
              <p>
                行业定制算法和模型，数据集和算法针对行业环境进行针对性优化，提升解决方案智慧化水平。
              </p>
            </div>
          </div>
        </div>
        <div class="qua_box_none">
          <div class="wrap2">
            <div class="wrap2_img">
              <img src="../../assets/images/tedian1.png" alt="" />
            </div>
          </div>
          <div class="content">
            <p>深耕行业</p>
            <p>
              丰富的行业实践经验，团队对行业生产、管理流程有着深刻的理解，针对用户痛点提出专业解决方案。
            </p>
          </div>
        </div>
        <div class="qua_box_none">
          <div class="wrap2">
            <div class="wrap2_img">
              <img src="../../assets/images/tedian2.png" alt="" />
            </div>
          </div>
          <div class="content">
            <p>专业团队</p>
            <p>
              专业的管理及售后团队，前期能够深刻理解用户需求，后期针对售后问题全球快速响应。
            </p>
          </div>
        </div>
        <div class="qua_box_none">
          <div class="wrap2">
            <div class="wrap2_img">
              <img src="../../assets/images/tedian3.png" alt="" />
            </div>
          </div>
          <div class="content">
            <p>定制化服务</p>
            <p>
              行业定制算法和模型，数据集和算法针对行业环境进行针对性优化，提升解决方案智慧化水平。
            </p>
          </div>
        </div>
      </div>
      <div id="concept">
        <p>团队开发理念</p>
        <p>
          阅图智能科技位于上海，依托强大港口资源优势，服务范围辐射全国及海外，秉承以用户为核心的理念，坚持技术主导，不断增强解决方案核心优势，提升团队专业效率，与客户一路相携，提升行业安全管理水平与生产效率。
        </p>
      </div>
      <div id="news">
        <p class="p1">新闻中心</p>
        <div class="news_wrap">
          <div class="box1"></div>
          <div class="box2">
            <div class="box2_left">
              <div style="display: flex; align-items: center">
                <p class="p2">近期大事件</p>
                <p class="p3">2022-07-01</p>
              </div>
              <div>
                <p class="p4">
                  沪东智能管理平台正式上线，该平台集成了一键报警、大屏数据展示、实时告警、告警视频弹窗、数据统计分析等功能，为客户提供一整套安全预警管理系统。
                </p>
              </div>
            </div>
            <div class="box2_right">
              <img src="../../assets/images/newsCenter.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div id="address">
        <p class="p_title">办公地址</p>
        <div class="address_wrap">
          <div class="addressBox1">
            <p class="p1">地址</p>
            <p class="p2">上海市徐汇区1000号1205室</p>
            <p class="p3">电话</p>
            <p class="p4">021-54256965</p>
            <p class="p5">邮箱</p>
            <p class="p6">yuetu@yuetuai.com</p>
          </div>
          <div class="addressBox2">
            <baidu-map
              id="allmap"
              :mapClick="false"
              :zoom="zoom"
              @click="click"
              @ready="mapReady"
              :scroll-wheel-zoom="true"
            >
              <!-- <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale> -->
              <!-- <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation> -->
              <bm-marker :position="point" animation="BMAP_ANIMATION_BOUNCE">
              </bm-marker>
            </baidu-map>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <NavFooter></NavFooter>
    </footer>
    <Scroll></Scroll>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar/navBar.vue";
import NavFooter from "../../components/Footer/footer.vue";
import Scroll from "../../components/scroll/scroll.vue";

export default {
  data() {
    return {
      status: "",
      navList: [
        {
          id: "#profile",
          name: "公司简介",
        },
        {
          id: "#qualification",
          name: "荣誉资质",
        },
        {
          id: "#concept",
          name: "团队开发理念",
        },
        {
          id: "#news",
          name: "新闻中心",
        },
        {
          id: "#address",
          name: "办公地址",
        },
      ],
      zoom: 14,
      navActived: 0,
      isFixed: false,
      center: { lng: 121.439979, lat: 31.163217 },
      point: "",
    };
  },
  methods: {
    mapReady({ BMap, map }) {
      this.point = new BMap.Point(121.439979, 31.163217);
      map.centerAndZoom(this.point, 12);
      map.setZoom(17);
    },
    click(data) {
      console.log(data);
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let offsetTop = document.querySelector("#main-nav").offsetTop;
      this.isFixed = scrollTop > offsetTop ? true : false;
      if (scrollTop < 600) {
        this.isFixed = false;
      }
      if (scrollTop < 933) {
        this.navActived = 0;
      }
      if (scrollTop > 1000) {
        this.navActived = 1;
      }
      if (scrollTop > 1600) {
        this.navActived = 2;
      }
      if (scrollTop > 1960) {
        this.navActived = 3;
      }
      if (scrollTop > 2510) {
        this.navActived = 4;
      }
    },
    handleClick(index, id) {
      setTimeout(() => {
        this.navActived = index;
      }, 500);
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    toLocal() {
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
      }
    },
  },
  created() {
    this.$nextTick(() => {
      this.toLocal();
    });
  },
  mounted() {
    this.status = 3;
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    localStorage.setItem("toId", "");
  },
  components: {
    NavBar,
    NavFooter,
    Scroll,
  },
};
</script>

<style lang='scss' scoped>
@import "./about.scss";
</style>